import React, { useEffect, useState } from 'react'
import { Image } from 'src/graphql/generated/graphql-types'
import ImageComponent from '@dustin-web/microsite-components/src/image'
import classnames from 'classnames'
import ImageUnknownWidth from '@dustin-web/microsite-components/src/image/image-nowidth'

type Props = {
  image: Image
  alt?: string
  restrictHeight?: boolean
  intrinsicSize?: boolean
}

export const ImageBlock = (props: Props) => {
  const [imageHeight, setImageHeight] = useState(100)
  const { image, alt, restrictHeight, intrinsicSize } = props

  useEffect(() => {
    const img = document.createElement('img') as HTMLImageElement
    img.src = image.url
    setImageHeight(img.height)
  }, [props])
  return (
    <div
      className={classnames('u-mb-16 u-w-100 c-image-block', {
        'c-image-block--restrict-height': restrictHeight,
      })}
      style={restrictHeight ? { minHeight: 100, maxHeight: 400 } : { minHeight: 'initial' }}>
      {restrictHeight ? (
        <ImageComponent
          alt={alt ?? ''}
          src={image.url}
          height={imageHeight}
          width={1136}
          style={restrictHeight ? { objectFit: 'cover' } : { objectFit: 'initial' }}
          className="u-radius-8"
        />
      ) : (
        <ImageUnknownWidth
          src={image.url}
          alt={alt ?? ''}
          style={intrinsicSize ? { width: 'auto' } : { width: '100%' }}
        />
      )}
    </div>
  )
}
